module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ParallelDevs","short_name":"PDevs","start_url":"/","background_color":"#000000","theme_color":"#00b3ea","display":"minimal-ui","icons":[],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag-cookieconsent/gatsby-browser.js'),
      options: {"plugins":[],"cookieConsentConfig":{"categories":{"necessary":{"enabled":true,"readOnly":true},"analytics":{}},"language":{"default":"en","translations":{"en":{"consentModal":{"title":"Cookie Consent","description":"We use cookies to enhance your browsing experience. By continuing to use our site, you agree to our cookie policy.","acceptAllBtn":"Accept all","acceptNecessaryBtn":"Reject all","showPreferencesBtn":"Preferences"},"preferencesModal":{"title":"Manage cookie preferences","acceptAllBtn":"Accept all","acceptNecessaryBtn":"Reject all","savePreferencesBtn":"Accept current selection","closeIconLabel":"Close modal","sections":[{"title":"Somebody said ... cookies?","description":"I want one!"},{"title":"Strictly Necessary cookies","description":"These cookies are essential for the proper functioning of the website and cannot be disabled.","linkedCategory":"necessary"},{"title":"Performance and Analytics","description":"These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.","linkedCategory":"analytics"},{"title":"More information","description":"For any queries in relation to my policy on cookies and your choices, please <a href=\"#contact-page\">contact us</a>"}]}}}},"guiOptions":{"consentModal":{"layout":"box","position":"bottom left","flipButtons":false,"equalWeightButtons":true},"preferencesModal":{"layout":"box","flipButtons":false,"equalWeightButtons":true}}},"enableForAllEnvironments":true,"googleGtagPluginConfig":{"trackingIds":["G-568H2YCDJB"],"pluginConfig":{"head":false,"respectDNT":true,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
