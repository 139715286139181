import React from 'react';
import mixpanel from 'mixpanel-browser';

import { SiteContextProvider } from './src/context/SiteContext';

const wrapRootElement = ({ element }) => <SiteContextProvider>{element}</SiteContextProvider>;

export { wrapRootElement }; // eslint-disable-line import/prefer-default-export

export const onInitialClientRender = () => {
  // Load your external code here
  mixpanel.init('b484b269c0d99ae747305a0bd49912c3', { debug: false, track_pageview: true });
  mixpanel.identify('ParalleldevsUID');
  mixpanel.track('Signed Up', {
    'Signup Type': 'Referral'
  });

  const smartlookScript = document.createElement('script');
  smartlookScript.type = 'text/javascript';
  smartlookScript.innerHTML = `
    window.smartlook||(function(d) {
      var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
      var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
      c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);
      })(document);
      smartlook('init', '97df08a710f5ed233f2da0857a7d7d23dafbd439', { region: 'eu' });
  `;
  document.head.appendChild(smartlookScript);

  const script2 = document.createElement('script');
  script2.src = 'https://tag.clearbitscripts.com/v1/pk_6ca109d5c77092755ae3485345e6008f/tags.js';
  script2.async = true;
  script2.referrerPolicy = 'strict-origin-when-cross-origin';
  document.head.appendChild(script2);

  const script3 = document.createElement('script');
  script3.innerHTML = `
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '2026316574388608');
    fbq('track', 'PageView');
  `;
  document.head.appendChild(script3);

  const noscript = document.createElement('noscript');
  noscript.innerHTML = `
    <img
      height="1"
      width="1"
      style="display:none"
      src="https://www.facebook.com/tr?id=2026316574388608&ev=PageView&noscript=1"
    />
  `;
  document.body.appendChild(noscript);
};
