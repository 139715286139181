import React from 'react';

const defaultState = {
  showContact: false,
  navigation: [],
  toggleContact: () => {}
};
const SiteContext = React.createContext(defaultState);

class SiteContextProvider extends React.Component {
  state = {
    showContact: false,
    navigation: [
      {
        uri: '/',
        label: 'Home'
      },
      {
        uri: '/services/',
        label: 'Services'
      },
      {
        uri: '/case-studies/',
        label: 'Case Studies'
      },
      {
        uri: '/about/',
        label: 'About'
      },
      {
        uri: '/testimonial/',
        label: 'Reviews'
      },
      {
        uri: '/blog/',
        label: 'Blog'
      },
      {
        uri: '/jobs/',
        label: 'Careers'
      },
      {
        uri: '/contact-us/',
        label: "LET'S TALK"
      }
    ]
  };

  isActive = ({ isCurrent }) => (isCurrent ? { className: 'uk-active' } : null);

  toggleContact = () => {
    const showContact = !this.state.showContact; // eslint-disable-line react/destructuring-assignment
    this.setState(() => ({ showContact }));
  };

  render() {
    const { children } = this.props;
    const { showContact, navigation } = this.state;
    return (
      <SiteContext.Provider
        value={{
          showContact,
          navigation,
          isActive: this.isActive,
          toggleContact: this.toggleContact
        }}>
        {children}
      </SiteContext.Provider>
    );
  }
}

export default SiteContext;

export { SiteContextProvider };
